.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    inset-inline-start: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #2196f3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

a {
    text-decoration: none;
}

.edit-form .card-header h4 {
    color: #000;
}

.swal2-popup {
    font-size: 10px !important;
}

.btn-dark-blue {
    background-color: #0a1843 !important;
    border: white 1px solid;
    color: white !important;
}

#stepper1 {
    width: 100%;
    float: inline-end;
}

.cardHolder {
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.admin-socio-economic-table tbody tr td:nth-child(1) {
    color: #000 !important;
    font-weight: bold;
}

.admin-custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.admin-custom-scrollbar::-webkit-scrollbar-track {
    background-color: gray;
    border-radius: 8px;
}

.admin-custom-scrollbar::-webkit-scrollbar-thumb {
    box-shadow: inset 10px 10px 10px 10px black;
    border-radius: 8px;
}

.text-align-right {
    text-align: inline-end;
}

.main-sidebar {
    position: fixed !important;
    overflow-y: auto !important;
    height: 100vh !important;
}

.main-sidebar::-webkit-scrollbar {
    width: 8px;
}

.main-sidebar::-webkit-scrollbar-track {
    background-color: gray;
    border-radius: 8px;
}

.main-sidebar::-webkit-scrollbar-thumb {
    box-shadow: inset 10px 10px 10px 10px #222;
    border-radius: 8px;
}

.btn-view {
    color: #fff !important;
    background-color: #6fc759 !important;
    border-color: #6fc759 !important;
    box-shadow: none !important;
}

.btnEdit:hover {
    background-color: white !important;
    color: black;
    border-color: black !important;
}

.custom-file-input2::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input2::before {
    content: 'Import Excel';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.custom-file-input2:hover::before {
    border-color: black;
}

.custom-file-input2:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.canvasjs-chart-credit {
    display: "none" !important;
}

.dropdown-heading {
    height: 30px !important;
    width: 100% !important;
    margin: 0px !important;
}

.css-yk16xz-control {
    height: 30px !important;
    width: 100% !important;
    margin: 0px !important;
    min-height: unset !important;
    border-color: #ccc !important;
}

.css-g1d714-ValueContainer {
    height: 30px !important;
    border-color: #ccc !important;
}

.cstm-input, .dropdown-container {
    border: 1px solid #ccc !important;
}

.css-yk16xz-control {
    height: 38px !important;
    border-color: #ccc !important;
}

.btn .btn-lin {
    color: #fff;
    background-color: #356f8f;
    border-color: #356f8f;
    box-shadow: none;
}

