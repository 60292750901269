/* @extend display-flex; */
display-flex, .display-flex, .display-flex-center, .signup-content, .signin-content, .social-login, .socials {
    display: flex;
    display: -webkit-flex; }

  figure {
    margin: 0; }

  
  
  .main {
    background: #f8f8f8;
    padding: 150px 0; }
  
  .clear {
    clear: both; 
  }
  
  
  .containerReg {
    width: 900px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px; 
  }

  .react-tel-input{
    width: 100% !important;
  }

  .selected-flag{
    border: 1px solid #cacaca !important;
    height: 38px !important;
    background-color: white !important;
  }

  .flag-dropdown {
	  background-color:unset !important;
    border: unset !important
  }

  input[type=tel]{
    height: 39px !important;
  }

  .display-flex {
    justify-content: space-between;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center; }
  
  .display-flex-center {
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center; }
  
  .position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); }
  
  .signup {
    margin-bottom: 150px; }
  
  .signup-content {
    padding: 75px 0; }
  
  .signup-form, .signup-image, .signin-form, .signin-image {
    width: 50%;
  }
  
  .signup-image {
    margin: 0 55px; }
  
  .form-title {
    margin-bottom: 33px; }
  
  .signup-image {
    margin-top: 45px; }
  
  figure {
    margin-bottom: 50px;
    text-align: center; }
  
  .form-submit {
    display: inline-block;
    background: #6dabe4;
    color: #fff;
    border-bottom: none;
    width: auto;
    padding: 15px 39px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    margin-top: 25px;
    cursor: pointer; }
    .form-submit:hover {
      background: #4292dc; }
  
  #signin {
    margin-top: 16px; }
  
  .signup-image-link {
    font-size: 14px;
    color: #222;
    display: block;
    text-align: center; }
  
  .term-service {
    font-size: 13px;
    color: #222; }
  
  .signup-form {
    margin-left: 75px;
    margin-right: 75px;
    padding-left: 34px; }
  
  .register-form {
    width: 100%; }
  
  .form-group2 {
    position: relative;
    overflow: hidden;
  }
  .inputHolder {
    width: 100% !important;
    display: block !important;
    border: none !important;
    border-bottom: 1px solid #999 !important;
    padding: 6px 30px !important;
    font-family: Poppins !important;
    box-sizing: border-box !important;
  }

  .agree-term {
    display: inline-block;
    width: auto; }
  
  
  .material-icons-name {
    font-size: 18px; }
  
  .signin-content {
    padding-top: 67px;
    padding-bottom: 87px; }
  
  .social-login {
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center;
    margin-top: 80px; }
  
  .social-label {
    display: inline-block;
    margin-right: 15px; }
  
  .socials li {
    padding: 5px; }
    .socials li:last-child {
      margin-right: 0px; }
    .socials li a {
      text-decoration: none; }
      .socials li a i {
        width: 30px;
        height: 30px;
        color: #fff;
        font-size: 14px;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        -o-border-radius: 5px;
        -ms-border-radius: 5px;
        transform: translateZ(0);
        -moz-transform: translateZ(0);
        -webkit-transform: translateZ(0);
        -o-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out; }
    .socials li:hover a i {
      -webkit-transform: scale(1.3) translateZ(0);
      transform: scale(1.3) translateZ(0); }
  
  .zmdi-facebook {
    background: #3b5998; }
  
  .zmdi-twitter {
    background: #1da0f2; }
  
  .zmdi-google {
    background: #e72734; }
  
  .signin-form {
    margin-right: 90px;
    margin-left: 80px; }
  
  .signin-image {
    margin-left: 110px;
    margin-right: 20px;
    margin-top: 10px; }
  
  @media screen and (max-width: 1200px) {
    .containerReg {
      width: calc( 100% - 30px);
      max-width: 100%; } }
  @media screen and (min-width: 1024px) {
    .containerReg {
      max-width: 1200px; } }
  @media screen and (max-width: 768px) {
    .signup-content, .signin-content {
      flex-direction: column;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      -o-flex-direction: column;
      -ms-flex-direction: column;
      justify-content: center;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      -o-justify-content: center;
      -ms-justify-content: center; }
  
    .signup-form {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      /* box-sizing: border-box; */
      padding: 0 30px; }
  
    .signin-image {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 50px;
      order: 2;
      -moz-order: 2;
      -webkit-order: 2;
      -o-order: 2;
      -ms-order: 2; }
  
    .signup-form, .signup-image, .signin-form, .signin-image {
      width: auto; }
  
    .social-login {
      justify-content: center;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      -o-justify-content: center;
      -ms-justify-content: center; }
  
    .form-button {
      text-align: center; }
  
    .signin-form {
      order: 1;
      -moz-order: 1;
      -webkit-order: 1;
      -o-order: 1;
      -ms-order: 1;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0 30px; }
  
    .form-title {
      text-align: center; } }
  @media screen and (max-width: 400px) {
    .social-login {
      flex-direction: column;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      -o-flex-direction: column;
      -ms-flex-direction: column; }
  
    .social-label {
      margin-right: 0px;
      margin-bottom: 10px; } }

  
